<template>
  <div class="custom-line-item-template">
    <table width="100%" class="line-item-multiple-table">
      <thead>
        <tr>
          <th height="40px" width="54%" class="pl-4 font-size-16">
            {{ itemTitle }} ({{ line_items.length }})
          </th>
          <th height="40px" width="10%" class="font-size-16" align="right">
            Quantity
          </th>
          <th height="40px" width="18%" class="font-size-16" align="right">
            Rate
          </th>
          <th height="40px" width="18%" class="font-size-16 pr-4" align="right">
            Total
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-if="loading">
          <tr>
            <td colspan="4">
              <div class="my-10 mx-5 d-flex align-center justify-center">
                <v-progress-circular
                  :width="3"
                  color="cyan"
                  indeterminate
                ></v-progress-circular>
                <p class="font-size-16 mx-5 my-0 font-weight-600">Loading...</p>
              </div>
            </td>
          </tr>
        </template>
        <template v-else-if="line_items.length">
          <template v-for="(row, index) in line_items">
            <tr
              :key="`line-item-package-${index}`"
              class="line-item-package-row"
              :class="{
                'line-item-odd-row': is_odd_no(index),
                'line-item-even-row': is_even_no(index),
              }"
            >
              <td valign="top" width="54%" class="pl-4">
                <br />
                <v-layout>
                  <v-flex class="text-center" v-if="row.product_image" md2>
                    <v-avatar size="100">
                      <v-img contain :src="row.product_image"></v-img>
                    </v-avatar>
                  </v-flex>
                  <v-flex>
                    <v-text-field
                      dense
                      filled
                      hide-details
                      color="cyan"
                      solo
                      flat
                      readonly
                      :placeholder="itemTitle"
                      v-model="row.product"
                    >
                      <template v-slot:prepend-inner>
                        <v-tooltip top content-class="custom-top-tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              small
                              label
                              class="mr-2 custom-status font-weight-600 custom-grey-border text-uppercase"
                              text-color="white"
                              color="orange"
                            >
                              {{ row.barcode }}
                            </v-chip>
                          </template>
                          <span class="text-capitalize"
                            >{{ get_product_type(row.product_type) }} #</span
                          >
                        </v-tooltip>
                      </template>
                      <template v-slot:append>
                        <v-tooltip top content-class="custom-top-tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              small
                              label
                              class="mr-2 custom-status font-weight-600 custom-grey-border text-uppercase"
                              text-color="white"
                              color="orange"
                            >
                              {{ get_product_type(row.product_type) }}
                            </v-chip>
                          </template>
                          <span class="text-capitalize">Type</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                    <TinyMCE
                      v-model.trim="row.description"
                      v-on:keyup="update_package_value(index)"
                      v-on:change="update_package_value(index)"
                    ></TinyMCE>
                  </v-flex>
                </v-layout>
                <br />
              </td>
              <td valign="top" width="10%" align="right">
                <br />
                <v-layout class="line-item-uom">
                  <v-flex style="width: 60px">
                    <v-text-field
                      dense
                      filled
                      hide-details
                      color="cyan"
                      solo
                      flat
                      type="number"
                      v-on:keypress="is_number($event)"
                      placeholder="Quantity"
                      v-model="row.quantity"
                      v-on:change="update_package_value(index)"
                      v-on:click="update_package_value(index)"
                      v-on:keyup="update_package_value(index)"
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex style="width: 100px">
                    <v-select
                      :items="uomList"
                      dense
                      filled
                      flat
                      hide-details
                      solo
                      v-model="row.uom"
                      placeholder="uom"
                      color="cyan"
                      item-color="cyan"
                    ></v-select>
                  </v-flex>
                </v-layout>
              </td>
              <td valign="top" width="18%" align="right">
                <br />
                <v-text-field
                  dense
                  filled
                  hide-details
                  color="cyan"
                  solo
                  flat
                  prefix="$"
                  type="number"
                  v-on:keypress="is_number($event)"
                  placeholder="Rate"
                  v-model="row.rate"
                  v-on:change="update_package_value(index)"
                  v-on:click="update_package_value(index)"
                  v-on:keyup="update_package_value(index)"
                ></v-text-field>
                <br />
                <p class="w-100 my-2 text-left font-size-16 font-weight-500">
                  <v-layout class="align-center">
                    <v-flex style="width: 100px">Supplier Cost:</v-flex>
                    <v-flex style="padding-right: 10px">
                      <template v-if="row.edit_supplier_cost">
                        <v-text-field
                          dense
                          :ref="`supplier-cost${index}`"
                          filled
                          hide-details
                          color="cyan"
                          solo
                          flat
                          prefix="$"
                          type="number"
                          v-on:keypress="is_number($event)"
                          placeholder="Supplier Cost"
                          v-model="row.company_cost"
                          v-on:change="
                            emit_value();
                            row.edit_supplier_cost = false;
                          "
                          v-on:blur="row.edit_supplier_cost = false"
                        ></v-text-field>
                      </template>
                      <template v-else>
                        <div class="d-flex align-center">
                          <label class="m-0 p-0 font-weight-600">{{
                            formatMoney(row.company_cost)
                          }}</label>
                          <v-btn
                            v-on:click="edit_supplier(index)"
                            small
                            icon
                            color="cyan"
                          >
                            <v-icon small>mdi-pencil</v-icon>
                          </v-btn>
                        </div>
                      </template>
                    </v-flex>
                  </v-layout>
                </p>
                <p class="w-100 my-2 text-left font-size-16 font-weight-500">
                  Price:&nbsp;&nbsp;<label class="m-0 p-0 font-weight-600">{{
                    formatMoney(row.rate)
                  }}</label>
                </p>
                <p class="w-100 my-2 text-left font-size-16 font-weight-500">
                  Profit:&nbsp;&nbsp;<label class="m-0 p-0 font-weight-600">{{
                    get_profit(index)
                  }}</label>
                </p>
                <p class="w-100 my-2 text-left font-size-16 font-weight-500">
                  Net Price:&nbsp;&nbsp;<label
                    class="m-0 p-0 font-weight-600"
                    >{{ formatMoney(row.net_price) }}</label
                  >
                </p>
              </td>
              <td valign="top" width="18%" align="right" class="pr-4">
                <br />
                <v-text-field
                  dense
                  filled
                  hide-details
                  color="cyan"
                  solo
                  flat
                  readonly
                  prefix="$"
                  type="number"
                  class="has-delete-outer-icon"
                  append-outer-icon="mdi-delete"
                  v-on:click:append-outer="remove_row(row, index)"
                  v-on:keypress="is_number($event)"
                  placeholder="Total"
                  v-model="row.total"
                >
                </v-text-field>
                <br />
                <p class="w-100 my-2 text-left font-size-16 font-weight-500">
                  Total Supplier Cost:&nbsp;&nbsp;<label
                    class="m-0 p-0 font-weight-600"
                    >{{ get_total_company_cost(index) }}</label
                  >
                </p>
                <p class="w-100 my-2 text-left font-size-16 font-weight-500">
                  Price:&nbsp;&nbsp;<label class="m-0 p-0 font-weight-600">{{
                    get_total_charges(index)
                  }}</label>
                </p>
                <p class="w-100 my-2 text-left font-size-16 font-weight-500">
                  Profit:&nbsp;&nbsp;<label class="m-0 p-0 font-weight-600">{{
                    get_total_profit(index)
                  }}</label>
                </p>
                <p class="w-100 my-2 text-left font-size-16 font-weight-500">
                  Net Price:&nbsp;&nbsp;<label
                    class="m-0 p-0 font-weight-600"
                    >{{ get_net_price(index) }}</label
                  >
                </p>
              </td>
            </tr>
            <template v-if="row.line_items && row.line_items.length">
              <tr
                v-for="(child, cindex) in row.line_items"
                :key="`line-item-package-${index}-${cindex}`"
                class="line-item-package-row"
                :class="{
                  'line-item-odd-row': is_odd_no(index),
                  'line-item-even-row': is_even_no(index),
                  'line-item-strike': child.strike,
                }"
              >
                <td valign="top" width="54%" class="pl-4">
                  <br />
                  <v-layout>
                    <v-flex class="text-center" v-if="child.product_image" md2>
                      <v-avatar size="100">
                        <v-img contain :src="child.product_image"></v-img>
                      </v-avatar>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        dense
                        filled
                        hide-details
                        color="cyan"
                        solo
                        flat
                        readonly
                        :placeholder="itemTitle"
                        v-model="child.product"
                      >
                        <template v-slot:prepend-inner>
                          <v-tooltip top content-class="custom-top-tooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                v-bind="attrs"
                                v-on="on"
                                small
                                label
                                class="mr-2 custom-status font-weight-600 custom-grey-border text-uppercase"
                                text-color="white"
                                color="orange"
                              >
                                {{ row.barcode }}
                              </v-chip>
                            </template>
                            <span class="text-capitalize"
                              >{{ get_product_type(row.product_type) }} #</span
                            >
                          </v-tooltip>
                          <v-tooltip top content-class="custom-top-tooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                v-bind="attrs"
                                v-on="on"
                                small
                                label
                                class="mr-2 custom-status font-weight-600 custom-grey-border text-uppercase"
                                text-color="white"
                                color="cyan"
                              >
                                {{ child.barcode }}
                              </v-chip>
                            </template>
                            <span class="text-capitalize"
                              >{{
                                get_product_type(child.product_type)
                              }}
                              #</span
                            >
                          </v-tooltip>
                        </template>
                        <template v-slot:append>
                          <v-tooltip top content-class="custom-top-tooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                v-bind="attrs"
                                v-on="on"
                                small
                                label
                                class="mr-2 custom-status font-weight-600 custom-grey-border text-uppercase"
                                text-color="white"
                                color="cyan"
                              >
                                {{ get_product_type(child.product_type) }}
                              </v-chip>
                            </template>
                            <span class="text-capitalize">Type</span>
                          </v-tooltip>
                        </template>
                      </v-text-field>
                      <v-textarea
                        auto-grow
                        dense
                        filled
                        flat
                        placeholder="Description"
                        solo
                        color="cyan"
                        hide-details
                        row-height="25"
                        v-model="child.description"
                        v-on:keyup="update_value(index, cindex)"
                        v-on:change="update_value(index, cindex)"
                      ></v-textarea>
                      <v-layout>
                        <v-flex v-if="false" md6>
                          <v-checkbox
                            hide-details
                            class="my-2 py-0 d-inline-block"
                            color="cyan"
                            label="Is Optional?"
                            :true-value="1"
                            :false-value="0"
                            v-model="child.is_optional"
                            v-on:change="update_value(index, cindex)"
                          ></v-checkbox>
                        </v-flex>
                        <v-flex v-if="!isPackage" md6>
                          <v-checkbox
                            hide-details
                            class="my-2 py-0 d-inline-block"
                            color="cyan"
                            label="Is Removed?"
                            :true-value="1"
                            :false-value="0"
                            v-model="child.strike"
                            v-on:change="update_value(index, cindex)"
                          ></v-checkbox>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <br />
                </td>
                <td valign="top" width="10%" align="right">
                  <br />
                  <v-text-field
                    dense
                    filled
                    hide-details
                    color="cyan"
                    solo
                    flat
                    type="number"
                    v-on:keypress="is_number($event)"
                    placeholder="Quantity"
                    v-model="child.quantity"
                    v-on:change="update_value(index, cindex)"
                    v-on:click="update_value(index, cindex)"
                    v-on:keyup="update_value(index, cindex)"
                  ></v-text-field>
                </td>
                <td valign="top" width="18%" align="right"></td>
                <td valign="top" width="18%" align="right" class="pr-4"></td>
              </tr>
            </template>
          </template>
        </template>
        <template v-else>
          <tr>
            <td colspan="4">
              <p
                class="m-0 my-5 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no
                <span class="text-lowercase">{{ itemTitle }}</span>
                at the moment.
              </p>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <Dialog :common-dialog="delete_dialog">
      <template v-slot:title> Delete Confirmation </template>
      <template v-slot:body>
        <v-row>
          <v-col md="10" class="py-0" offset-md="1">
            Are you sure, you want to delete
            {{ get_product_type(delete_row.product_type) }}
            <b>{{ delete_row.barcode }} - {{ delete_row.product }}</b
            >?
          </v-col>
        </v-row>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="delete_loading"
          :loading="delete_loading"
          class="mx-2 custom-grey-border custom-bold-button white--text"
          color="red lighten-1"
          v-on:click="delete_row_confirm()"
        >
          Yes! Delete
        </v-btn>
        <v-btn
          :disabled="delete_loading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="delete_dialog = false"
        >
          No! Cancel
        </v-btn>
      </template>
    </Dialog>
  </div>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ObjectPath from "object-path";
import Dialog from "@/view/pages/partials/Dialog.vue";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import { QUERY, DELETE } from "@/core/services/store/request.module";

export default {
  name: "line-item",
  model: {
    prop: "value",
    event: "input",
  },
  components: {
    Dialog,
    TinyMCE,
  },
  mixins: [CommonMixin],
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(param) {
        if (!this.lodash.isEqual(param, this.line_items)) {
          this.init_line_item(param);
        }
      },
    },
  },
  props: {
    value: {
      type: Array,
      default() {
        return new Array();
      },
    },
    uomList: {
      type: Array,
      default() {
        return new Array();
      },
    },
    itemTitle: {
      type: String,
      default: "Item",
    },
    updateMode: {
      type: Boolean,
      default: false,
    },
    isPackage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      line_items: [],
      loading: false,
      delete_type: "parent",
      delete_loading: false,
      delete_dialog: false,
      delete_row: {},
      delete_index: -1,
      delete_child_index: -1,
    };
  },
  methods: {
    edit_supplier(index) {
      this.line_items[index].edit_supplier_cost = true;
      this.$nextTick(() => {
        this.$refs[`supplier-cost${index}`][0].$refs.input.focus();
      });
    },
    is_odd_no(index) {
      return Math.abs(index % 2) == 1;
    },
    is_even_no(index) {
      return index % 2 == 0;
    },
    get_product_type(type) {
      if (type == "service") {
        return "Additional Work";
      } else {
        return type;
      }
    },
    delete_row_confirm() {
      this.delete_loading = true;
      this.$store
        .dispatch(DELETE, {
          url: `line-item/${this.delete_row.id}`,
        })
        .then(() => {
          this.delete_dialog = false;
          if (this.delete_type == "parent") {
            ObjectPath.del(this.line_items, this.delete_index);
            this.$nextTick(() => {
              this.emit_value();
              this.delete_row = {};
              this.delete_type = "parent";
              this.delete_index = -1;
              this.delete_child_index = -1;
            });
          } else if (this.delete_type == "child") {
            ObjectPath.del(
              this.line_items,
              `${this.delete_index}.line_items.${this.delete_child_index}`
            );
            const line_items = ObjectPath.get(
              this.line_items,
              `${this.delete_index}.line_items`,
              []
            );

            if (line_items.length < 1) {
              ObjectPath.del(this.line_items, this.delete_index);
            }

            this.$nextTick(() => {
              this.emit_value();
              this.delete_row = {};
              this.delete_type = "parent";
              this.delete_index = -1;
              this.delete_child_index = -1;
            });
          }
        })
        .catch((error) => {
          console.log({ error });
        })
        .finally(() => {
          this.delete_loading = false;
        });
    },
    remove_row(row, index) {
      if (row.id) {
        this.delete_dialog = true;
        this.delete_row = row;
        this.delete_type = "parent";
        this.delete_index = index;
        this.delete_child_index = -1;
      } else {
        ObjectPath.del(this.line_items, index);
        this.$nextTick(() => {
          this.emit_value();
        });
      }
    },
    remove_child_row(row, index, cindex) {
      if (row.id) {
        this.delete_type = "child";
        this.delete_dialog = true;
        this.delete_row = row;
        this.delete_index = index;
        this.delete_child_index = cindex;
      } else {
        ObjectPath.del(this.line_items, `${index}.line_items.${cindex}`);
        const line_items = ObjectPath.get(
          this.line_items,
          `${index}.line_items`,
          []
        );

        if (line_items.length < 1) {
          ObjectPath.del(this.line_items, index);
        }

        this.$nextTick(() => {
          this.emit_value();
        });
      }
    },
    init_line_item(param) {
      const line_item = this.lodash.cloneDeep(param);
      if (line_item && line_item.length) {
        for (let i = 0; i < line_item.length; i++) {
          const index = this.lodash.findIndex(this.line_items, {
            product_id: line_item[i].product_id,
          });
          if (index >= 0) {
            this.line_items[index].status = line_item[i].status;
            this.line_items[index].product = line_item[i].product;
            this.line_items[index].product_type = line_item[i].product_type;
            this.line_items[index].product_id = line_item[i].product_id;
            this.line_items[index].description = line_item[i].description;
            this.line_items[index].quantity = line_item[i].quantity;
            this.line_items[index].rate = line_item[i].rate;
            this.line_items[index].uom = line_item[i].uom;
            this.line_items[index].total = line_item[i].total;
            this.line_items[index].strike = line_item[i].strike;
            this.line_items[index].company_cost = line_item[i].company_cost;
            this.line_items[index].charges = line_item[i].charges;
            this.line_items[index].profit = line_item[i].profit;
            this.line_items[index].is_optional = line_item[i].is_optional;
            this.line_items[index].line_items = line_item[i].line_items;
          } else {
            this.line_items.push(line_item[i]);
          }
        }
      }
    },
    get_profit(index) {
      const line_item = this.lodash.cloneDeep(this.line_items[index]);
      const profit = line_item.rate - line_item.company_cost;
      return this.formatMoney(profit);
    },
    get_total_company_cost(index) {
      const line_item = this.lodash.cloneDeep(this.line_items[index]);
      const company_cost = line_item.company_cost * line_item.quantity;
      return this.formatMoney(company_cost);
    },
    get_total_charges(index) {
      const line_item = this.lodash.cloneDeep(this.line_items[index]);
      const charges = line_item.rate * line_item.quantity;
      return this.formatMoney(charges);
    },
    get_net_price(index) {
      const line_item = this.lodash.cloneDeep(this.line_items[index]);
      const charges = line_item.net_price * line_item.quantity;
      return this.formatMoney(charges);
    },
    get_total_profit(index) {
      const line_item = this.lodash.cloneDeep(this.line_items[index]);
      const profit =
        (line_item.rate - line_item.company_cost) * line_item.quantity;
      return this.formatMoney(profit);
    },
    update_package_value(index) {
      if (this.line_items[index]) {
        const { rate, quantity } = this.line_items[index];
        this.line_items[index].total = rate * quantity;
        this.$nextTick(() => {
          this.emit_value();
        });
      }
    },
    update_value(index, cindex) {
      const line_item = ObjectPath.get(
        this.line_items,
        `${index}.line_items.${cindex}`,
        []
      );
      const { rate, quantity } = line_item;
      line_item.total = rate * quantity;
      ObjectPath.set(
        this.line_items,
        `${index}.line_items.${cindex}`,
        line_item
      );
      this.$nextTick(() => {
        this.emit_value();
      });
    },
    emit_value() {
      this.$nextTick(() => {
        this.$emit("input", this.line_items);
        this.$emit("change", this.line_items);
      });
    },
    get_line_items() {
      this.$store
        .dispatch(QUERY, {
          url: "line-item-services",
          data: {
            type: this.itemType,
            search: this.search,
            alphabet: this.alphabet,
            category: this.category,
          },
        })
        .then((response) => {
          this.line_items = ObjectPath.get(response, "data.line_items", []);
          this.categories = ObjectPath.get(response, "data.categories", []);
          this.emit_value();
        })
        .catch((error) => {
          this.logError(error);
          this.goBack();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
