<template>
  <div class="custom-line-item-template">
    <table width="100%" class="line-item-multiple-table additional-works">
      <thead>
        <tr>
          <th
            :class="color"
            height="40px"
            width="54%"
            class="font-size-16 pl-4"
          >
            {{ itemTitle }} ({{ line_items.length }})
          </th>
          <th
            :class="color"
            height="40px"
            width="10%"
            class="font-size-16"
            align="right"
          >
            Quantity
          </th>
          <th
            :class="color"
            height="40px"
            width="18%"
            class="font-size-16"
            align="right"
          >
            Rate
          </th>
          <th
            :class="color"
            height="40px"
            width="18%"
            class="font-size-16 pr-4"
            align="right"
          >
            Total
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-if="loading">
          <tr>
            <td colspan="4">
              <div class="my-10 mx-5 d-flex align-center justify-center">
                <v-progress-circular
                  :width="3"
                  color="cyan"
                  indeterminate
                ></v-progress-circular>
                <p class="font-size-16 mx-5 my-0 font-weight-600">Loading...</p>
              </div>
            </td>
          </tr>
        </template>
        <template v-else-if="line_items.length">
          <template v-for="(row, index) in line_items">
            <tr
              style="border-top: solid 1px #ccc"
              :key="`adtitle-${index}`"
              :class="{
                'line-item-one-row': index % 2 == 0,
                'line-item-two-row': !(index % 2 == 0),
              }"
            >
              <td valign="top" width="54%" class="pl-4">
                <br />
                <table width="100%" style="table-layout: fixed">
                  <tr>
                    <td width="100px" valign="top" align="center">
                      <label
                        class="py-0 my-0 mt-5 font-weight-600 font-size-18"
                      >
                        VO {{ index + 1 }}.
                      </label>
                    </td>
                    <td valign="top">
                      <v-autocomplete
                        dense
                        filled
                        color="cyan"
                        item-color="cyan"
                        :items="adtitle_list"
                        placeholder="Additional Work Title"
                        solo
                        flat
                        hide-details
                        item-text="name"
                        item-value="id"
                        class="my-2 width-100"
                        v-model.trim="row.product_id"
                        v-on:change="update_adtitle($event, index)"
                      >
                      </v-autocomplete>
                      <v-textarea
                        auto-grow
                        dense
                        filled
                        color="cyan"
                        placeholder="Description"
                        solo
                        flat
                        class="my-2"
                        hide-details
                        row-height="10"
                        v-model="row.description"
                        v-on:change="update_value(index)"
                      ></v-textarea>
                      <v-layout class="align-center">
                        <v-flex md6 class="text-left">
                          <v-btn
                            v-on:click="add_child_item(index)"
                            small
                            depressed
                            color="cyan white--text"
                            class="mb-4 custom-bold-button white--text"
                          >
                            <v-icon>mdi-plus</v-icon> Add Additional Work
                          </v-btn>
                        </v-flex>
                        <v-flex v-if="false" md3 class="text-right">
                          <v-checkbox
                            hide-details
                            class="my-2 py-0 d-inline-block"
                            color="cyan"
                            label="Is Removed?"
                            :true-value="1"
                            :false-value="0"
                            v-model="row.strike"
                            v-on:change="update_value(index)"
                          ></v-checkbox>
                        </v-flex>
                        <v-flex v-if="false" md3 class="text-right">
                          <v-checkbox
                            hide-details
                            class="my-2 py-0 d-inline-block"
                            color="cyan"
                            label="Is Optional?"
                            :true-value="1"
                            :false-value="0"
                            v-model="row.is_optional"
                            v-on:change="update_value(index)"
                          ></v-checkbox>
                        </v-flex>
                      </v-layout>
                    </td>
                  </tr>
                </table>
                <v-layout v-if="false" class="align-center">
                  <v-flex md4 class="text-left"> </v-flex>
                  <v-flex md4> </v-flex>
                  <v-flex md4 class="text-right"> </v-flex>
                  <v-flex v-if="false" md4 class="text-right pr-4"> </v-flex>
                </v-layout>
              </td>
              <td valign="top" width="10%" align="right"></td>
              <td valign="top" width="18%" align="right"></td>
              <td valign="middle" width="18%" align="right" class="pr-4">
                <v-btn
                  v-on:click="delete_adtitle(index)"
                  color="red lighten-1"
                  icon
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
            <template v-if="row.child_items">
              <template v-for="(crow, cindex) in row.child_items">
                <tr
                  v-if="crow.show_label"
                  style="border-top: solid 1px #ccc"
                  :key="`child-line-item-label-${index}-${cindex}`"
                  :class="{
                    'line-item-one-row': index % 2 == 0,
                    'line-item-two-row': !(index % 2 == 0),
                  }"
                >
                  <td valign="top" width="54%" class="pl-4">
                    <br />
                    <table width="100%" style="table-layout: fixed">
                      <tr>
                        <td width="110px" align="center" valign="top"></td>
                        <td>
                          <v-text-field
                            dense
                            filled
                            hide-details
                            color="cyan"
                            solo
                            flat
                            placeholder="Label"
                            v-model="crow.label"
                            v-on:change="update_value(index)"
                          >
                          </v-text-field>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td valign="top" width="10%" align="right"></td>
                  <td valign="top" width="18%" align="right"></td>
                  <td valign="top" width="18%" align="right" class="pr-4"></td>
                </tr>
                <tr
                  :style="
                    crow.show_label
                      ? ''
                      : crow.items && crow.items.length
                      ? 'border-top: solid 1px #ccc;'
                      : 'border-top: solid 1px #ccc; border-bottom: solid 1px #ccc;'
                  "
                  :key="`child-line-item-${index}-${cindex}`"
                  :class="{
                    'line-item-one-row': index % 2 == 0,
                    'line-item-two-row': !(index % 2 == 0),
                    'line-item-strike': crow.strike,
                  }"
                >
                  <td valign="top" width="54%" class="pl-4">
                    <br />
                    <table width="100%" style="table-layout: fixed">
                      <tr>
                        <td width="110px" align="right" valign="top">
                          <label
                            class="mb-0 mt-3 py-0 pl-0 pr-4 font-weight-600"
                          >
                            {{ index + 1 }}.{{ cindex + 1 }}
                          </label>
                        </td>
                        <td>
                          <v-layout>
                            <v-flex md11>
                              <v-autocomplete
                                dense
                                filled
                                color="cyan"
                                item-color="cyan"
                                :items="row.ad_select"
                                placeholder="Additional Work"
                                solo
                                flat
                                hide-details
                                class="width-100"
                                v-model="crow.product_id"
                                v-on:change="
                                  update_adwork($event, index, cindex)
                                "
                              >
                              </v-autocomplete>
                            </v-flex>
                            <v-flex md1>
                              <v-tooltip top content-class="custom-top-tooltip">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    style="height: 40px"
                                    v-on:click="
                                      crow.show_label = !crow.show_label;
                                      update_value(index);
                                    "
                                    v-bind="attrs"
                                    v-on="on"
                                    small
                                    class="w-100"
                                    depressed
                                    color="cyan white--text"
                                    ><v-icon>mdi-plus</v-icon></v-btn
                                  >
                                </template>
                                <span v-if="crow.show_label">Hide Area</span>
                                <span v-else>Show Area</span>
                              </v-tooltip>
                            </v-flex>
                          </v-layout>
                          <v-textarea
                            auto-grow
                            dense
                            filled
                            color="cyan"
                            placeholder="Description"
                            solo
                            flat
                            class="my-2"
                            hide-details
                            row-height="10"
                            v-model="crow.description"
                            v-on:change="update_value(index)"
                          ></v-textarea>
                          <v-layout v-if="!isPackage" class="align-center">
                            <v-flex md4>
                              <v-btn
                                v-on:click="add_level_two_item(index, cindex)"
                                small
                                depressed
                                color="cyan white--text"
                                class="custom-bold-button white--text"
                              >
                                <v-icon>mdi-plus</v-icon> Add Level 2
                              </v-btn>
                            </v-flex>
                            <v-flex md4 class="text-right">
                              <v-checkbox
                                v-if="summaryReport"
                                hide-details
                                class="my-2 py-0 d-inline-block"
                                color="cyan"
                                label="Is Removed?"
                                :true-value="1"
                                :false-value="0"
                                v-model="crow.strike"
                                v-on:change="
                                  marked_removed($event, index, cindex)
                                "
                              ></v-checkbox>
                            </v-flex>
                            <v-flex md4 class="text-right pr-4">
                              <v-checkbox
                                hide-details
                                class="my-2 py-0 d-inline-block"
                                color="cyan"
                                label="Is Optional?"
                                :true-value="1"
                                :false-value="0"
                                v-model="crow.is_optional"
                                v-on:change="
                                  marked_optional($event, index, cindex);
                                  update_optional_new_row(
                                    $event,
                                    index,
                                    cindex
                                  );
                                "
                              ></v-checkbox>
                            </v-flex>
                            <v-flex v-if="false" md4>
                              <v-checkbox
                                hide-details
                                class="my-2 py-0 d-inline-block"
                                color="cyan"
                                label="Is Removed?"
                                :true-value="1"
                                :false-value="0"
                                v-model="crow.strike"
                                v-on:change="update_child_total(index, cindex)"
                              ></v-checkbox>
                            </v-flex>
                          </v-layout>
                          <br />
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td valign="top" width="10%" align="right">
                    <br />
                    <v-layout class="line-item-uom">
                      <v-flex style="width: 60px">
                        <v-text-field
                          dense
                          filled
                          hide-details
                          color="cyan"
                          solo
                          flat
                          type="number"
                          v-on:keypress="is_number($event)"
                          placeholder="Quantity"
                          v-model="crow.quantity"
                          v-on:change="update_child_total(index, cindex)"
                          v-on:keyup="update_child_total(index, cindex)"
                        >
                        </v-text-field>
                      </v-flex>
                      <v-flex style="width: 100px">
                        <v-select
                          :items="uomList"
                          dense
                          filled
                          flat
                          hide-details
                          solo
                          v-model="crow.uom"
                          placeholder="UOM"
                          label="UOM"
                          color="cyan"
                          item-color="cyan"
                          v-on:change="update_value(index)"
                        ></v-select>
                      </v-flex>
                    </v-layout>
                  </td>
                  <td valign="top" width="18%" align="right">
                    <br />
                    <v-text-field
                      dense
                      filled
                      hide-details
                      color="cyan"
                      solo
                      flat
                      prefix="$"
                      type="number"
                      v-on:keypress="is_number($event)"
                      placeholder="Rate"
                      v-model="crow.rate"
                      v-on:change="update_child_total(index, cindex)"
                      v-on:keyup="update_child_total(index, cindex)"
                    ></v-text-field>

                    <p
                      class="w-100 my-2 text-left font-size-16 font-weight-500"
                    >
                      <v-layout class="align-center">
                        <v-flex style="width: 100px">Supplier Cost:</v-flex>
                        <v-flex style="padding-right: 10px">
                          <template v-if="crow.edit_supplier_cost">
                            <v-text-field
                              dense
                              :ref="`crow-supplier-price-${index}-${cindex}`"
                              filled
                              hide-details
                              color="cyan"
                              solo
                              flat
                              prefix="$"
                              type="number"
                              v-on:keypress="is_number($event)"
                              placeholder="Supplier Cost"
                              v-model="crow.company_cost"
                              v-on:change="
                                update_child_total(index, cindex);
                                crow.edit_supplier_cost = false;
                              "
                              v-on:blur="crow.edit_supplier_cost = false"
                            ></v-text-field>
                          </template>
                          <template v-else>
                            <div class="d-flex align-center">
                              <label class="m-0 p-0 font-weight-600">{{
                                formatMoney(crow.company_cost)
                              }}</label>
                              <v-btn
                                v-on:click="edit_child_supplier(index, cindex)"
                                small
                                icon
                                color="cyan"
                              >
                                <v-icon small>mdi-pencil</v-icon>
                              </v-btn>
                            </div>
                          </template>
                        </v-flex>
                      </v-layout>
                    </p>
                  </td>
                  <td valign="top" width="18%" align="right" class="pr-4">
                    <br />
                    <v-text-field
                      dense
                      filled
                      hide-details
                      color="cyan"
                      solo
                      flat
                      readonly
                      prefix="$"
                      type="number"
                      class="has-delete-outer-icon"
                      append-outer-icon="mdi-delete"
                      v-on:click:append-outer="
                        remove_child_row(crow, cindex, index)
                      "
                      v-on:keypress="is_number($event)"
                      placeholder="Total"
                      v-model="crow.total"
                    >
                    </v-text-field>
                    <p
                      class="w-100 my-3 text-left font-size-16 font-weight-500"
                    >
                      Total Supplier Cost:&nbsp;&nbsp;<label
                        class="m-0 p-0 font-weight-600"
                        >{{ calc_child_supplier(index, cindex) }}</label
                      >
                    </p>
                  </td>
                </tr>
                <template v-if="crow.items">
                  <template v-for="(clrow, clindex) in crow.items">
                    <tr
                      :style="
                        crow.items[clindex + 1]
                          ? ''
                          : 'border-bottom: solid 1px #ccc;'
                      "
                      :key="`child-level-one-line-item-${index}-${cindex}-${clindex}`"
                      :class="{
                        'line-item-one-row': index % 2 == 0,
                        'line-item-two-row': !(index % 2 == 0),
                        'line-item-strike': clrow.strike,
                      }"
                    >
                      <td valign="top" width="54%" class="pl-4">
                        <table width="100%" style="table-layout: fixed">
                          <tr>
                            <td width="110px" align="center" valign="top"></td>
                            <td width="50px" align="center" valign="top">
                              <v-icon class="mt-2">mdi-chevron-right</v-icon>
                            </td>
                            <td>
                              <v-textarea
                                auto-grow
                                dense
                                filled
                                color="cyan"
                                placeholder="Description"
                                solo
                                flat
                                hide-details
                                row-height="6"
                                v-model="clrow.description"
                                v-on:change="update_value(index)"
                              ></v-textarea>
                              <v-layout v-if="!isPackage" class="align-center">
                                <v-flex md4> </v-flex>
                                <v-flex md4 class="text-right">
                                  <v-checkbox
                                    v-if="summaryReport"
                                    hide-details
                                    class="my-2 py-0 d-inline-block"
                                    color="cyan"
                                    label="Is Removed?"
                                    :true-value="1"
                                    :false-value="0"
                                    v-model="clrow.strike"
                                    v-on:change="
                                      update_child_level_one_total(
                                        index,
                                        cindex,
                                        clindex
                                      )
                                    "
                                  ></v-checkbox>
                                </v-flex>
                                <v-flex md4 class="text-right pr-4">
                                  <v-checkbox
                                    hide-details
                                    class="my-2 py-0 d-inline-block"
                                    color="cyan"
                                    label="Is Optional?"
                                    :true-value="1"
                                    :false-value="0"
                                    v-model="clrow.is_optional"
                                    v-on:change="
                                      update_child_level_one_total(
                                        index,
                                        cindex,
                                        clindex
                                      );
                                      update_optional_new_row(
                                        $event,
                                        index,
                                        cindex,
                                        clindex
                                      );
                                    "
                                  ></v-checkbox>
                                </v-flex>
                                <v-flex v-if="false" md4>
                                  <v-checkbox
                                    hide-details
                                    class="my-2 py-0 d-inline-block"
                                    color="cyan"
                                    label="Is Removed?"
                                    :true-value="1"
                                    :false-value="0"
                                    v-model="clrow.strike"
                                    v-on:change="
                                      update_child_level_one_total(
                                        index,
                                        cindex,
                                        clindex
                                      )
                                    "
                                  ></v-checkbox>
                                </v-flex>
                              </v-layout>
                              <br />
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td valign="top" width="10%" align="right">
                        <v-layout class="line-item-uom">
                          <v-flex style="width: 60px">
                            <v-text-field
                              dense
                              filled
                              hide-details
                              color="cyan"
                              solo
                              flat
                              type="number"
                              v-on:keypress="is_number($event)"
                              placeholder="Quantity"
                              v-model="clrow.quantity"
                              v-on:change="
                                update_child_level_one_total(
                                  index,
                                  cindex,
                                  clindex
                                )
                              "
                              v-on:keyup="
                                update_child_level_one_total(
                                  index,
                                  cindex,
                                  clindex
                                )
                              "
                            >
                            </v-text-field>
                          </v-flex>
                          <v-flex style="width: 100px">
                            <v-select
                              :items="uomList"
                              dense
                              filled
                              flat
                              hide-details
                              solo
                              v-model="clrow.uom"
                              placeholder="UOM"
                              label="UOM"
                              color="cyan"
                              item-color="cyan"
                              v-on:change="update_value(index)"
                            ></v-select>
                          </v-flex>
                        </v-layout>
                      </td>
                      <td valign="top" width="18%" align="right">
                        <v-text-field
                          dense
                          filled
                          hide-details
                          color="cyan"
                          solo
                          flat
                          prefix="$"
                          type="number"
                          v-on:keypress="is_number($event)"
                          placeholder="Rate"
                          v-model="clrow.rate"
                          v-on:change="
                            update_child_level_one_total(index, cindex, clindex)
                          "
                          v-on:keyup="
                            update_child_level_one_total(index, cindex, clindex)
                          "
                        ></v-text-field>

                        <p
                          class="w-100 my-2 text-left font-size-16 font-weight-500"
                        >
                          <v-layout class="align-center">
                            <v-flex style="width: 100px">Supplier Cost:</v-flex>
                            <v-flex style="padding-right: 10px">
                              <template v-if="clrow.edit_supplier_cost">
                                <v-text-field
                                  dense
                                  :ref="`clrow-supplier-price-${index}-${cindex}-${clindex}`"
                                  filled
                                  hide-details
                                  color="cyan"
                                  solo
                                  flat
                                  prefix="$"
                                  type="number"
                                  v-on:keypress="is_number($event)"
                                  placeholder="Supplier Cost"
                                  v-model="clrow.company_cost"
                                  v-on:change="
                                    update_child_level_one_total(
                                      index,
                                      cindex,
                                      clindex
                                    );
                                    clrow.edit_supplier_cost = false;
                                  "
                                  v-on:blur="clrow.edit_supplier_cost = false"
                                ></v-text-field>
                              </template>
                              <template v-else>
                                <div class="d-flex align-center">
                                  <label class="m-0 p-0 font-weight-600">{{
                                    formatMoney(clrow.company_cost)
                                  }}</label>
                                  <v-btn
                                    v-on:click="
                                      edit_child_one_supplier(
                                        index,
                                        cindex,
                                        clindex
                                      )
                                    "
                                    small
                                    icon
                                    color="cyan"
                                  >
                                    <v-icon small>mdi-pencil</v-icon>
                                  </v-btn>
                                </div>
                              </template>
                            </v-flex>
                          </v-layout>
                        </p>
                      </td>
                      <td valign="top" width="18%" align="right" class="pr-4">
                        <v-text-field
                          dense
                          filled
                          hide-details
                          color="cyan"
                          solo
                          flat
                          readonly
                          prefix="$"
                          type="number"
                          class="has-delete-outer-icon"
                          append-outer-icon="mdi-delete"
                          v-on:click:append-outer="
                            remove_child_level_one_row(
                              clrow,
                              clindex,
                              cindex,
                              index
                            )
                          "
                          v-on:keypress="is_number($event)"
                          placeholder="Total"
                          v-model="clrow.total"
                        >
                        </v-text-field>
                        <p
                          class="w-100 my-3 text-left font-size-16 font-weight-500"
                        >
                          Total Supplier Cost:&nbsp;&nbsp;<label
                            class="m-0 p-0 font-weight-600"
                            >{{
                              calc_child_one_supplier(index, cindex, clindex)
                            }}</label
                          >
                        </p>
                      </td>
                    </tr>
                  </template>
                </template>
              </template>
            </template>
          </template>
        </template>
        <template v-else>
          <tr>
            <td colspan="4">
              <p
                class="m-0 my-5 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no
                <span class="text-lowercase">{{ itemTitle }}</span>
                at the moment.
              </p>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <Dialog :common-dialog="delete_dialog">
      <template v-slot:title> Delete Confirmation </template>
      <template v-slot:body>
        <v-row>
          <v-col md="10" class="py-0" offset-md="1">
            Are you sure, you want to delete
            {{ get_product_type(delete_row.product_type) }}
            <b>{{ delete_row.barcode }} - {{ delete_row.product }}</b
            >?
          </v-col>
        </v-row>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="delete_loading"
          :loading="delete_loading"
          class="mx-2 custom-grey-border custom-bold-button white--text"
          color="red lighten-1"
          v-on:click="delete_row_confirm()"
        >
          Yes! Delete
        </v-btn>
        <v-btn
          :disabled="delete_loading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="delete_dialog = false"
        >
          No! Cancel
        </v-btn>
      </template>
    </Dialog>
  </div>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ObjectPath from "object-path";
import Dialog from "@/view/pages/partials/Dialog.vue";
// import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import { GET, QUERY, DELETE } from "@/core/services/store/request.module";
import { QuotationEventBus } from "@/core/lib/quotation/quotation.lib";

export default {
  name: "line-item",
  model: {
    prop: "value",
    event: "input",
  },
  mixins: [CommonMixin],
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(param) {
        if (!this.lodash.isEqual(param, this.line_items)) {
          this.init_line_item(param);
        }
      },
    },
  },
  props: {
    value: {
      type: Array,
      default() {
        return new Array();
      },
    },
    uomList: {
      type: Array,
      default() {
        return new Array();
      },
    },
    itemTitle: {
      type: String,
      default: "Item",
    },
    color: {
      type: String,
      default: "blue--text text--darken-4",
    },
    updateMode: {
      type: Boolean,
      default: false,
    },
    isPackage: {
      type: Boolean,
      default: false,
    },
    summaryReport: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      additional_work_list: [],
      adtitle_list: [],
      line_items: [],
      loading: false,
      delete_loading: false,
      delete_dialog: false,
      delete_row: {},
      delete_index: -1,
    };
  },
  components: {
    Dialog,
    // TinyMCE,
  },
  methods: {
    update_adwork(productId, index, cindex) {
      if (productId) {
        let row = this.lodash.find(this.additional_work_list, {
          product_id: productId,
        });
        if (row) {
          this.line_items[index].child_items[cindex].product_id =
            row.product_id;
          this.line_items[index].child_items[cindex].description =
            row.description;
          this.line_items[index].child_items[cindex].quantity = 1;
          this.line_items[index].child_items[cindex].uom = row.uom;
          this.line_items[index].child_items[cindex].rate = row.rate;
          this.line_items[index].child_items[cindex].total = row.total;
          this.line_items[index].child_items[cindex].company_cost =
            row.company_cost;
          this.update_child_total(index, cindex);
          this.$nextTick(() => {
            this.update_value(index);
          });
        }
      }
    },
    get_adtitle() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "adtitle/all" })
        .then(({ data }) => {
          _this.adtitle_list = data;
        })
        .catch((error) => {
          console.log({ error });
        });
    },
    get_additional_work_list() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "line-item-services" })
        .then(({ data }) => {
          _this.additional_work_list = data.line_items;
        })
        .catch((error) => {
          console.log({ error });
        });
    },
    delete_adtitle(index) {
      ObjectPath.del(this.line_items, index);
      this.$nextTick(() => {
        this.update_value(index);
      });
    },
    update_adtitle(productId, index) {
      if (productId) {
        let row = this.lodash.find(this.adtitle_list, { id: productId });
        if (row) {
          this.line_items[index].barcode = null;
          this.line_items[index].charges = 0;
          this.line_items[index].company_cost = 0;
          this.line_items[index].uom = null;
          this.line_items[index].id = null;
          this.line_items[index].is_optional = 0;
          this.line_items[index].package_id = 0;
          this.line_items[index].product = row.name;
          this.line_items[index].description = row.description;
          this.line_items[index].product_id = row.id;
          this.line_items[index].product_image = null;
          this.line_items[index].product_type = "adtitle";
          this.line_items[index].net_price = 0;
          this.line_items[index].profit = 0;
          this.line_items[index].quantity = 0;
          this.line_items[index].rate = 0;
          this.line_items[index].strike = 0;
          this.line_items[index].status = true;
          this.line_items[index].edit_supplier_cost = false;
          this.line_items[index].total = 0;
          this.line_items[index].child_sum = 0;
          this.line_items[index].optional_child_sum = 0;
          this.line_items[index].label = null;
          this.line_items[index].show_label = false;
          this.$store
            .dispatch(GET, { url: `adtitle/${productId}` })
            .then(({ data }) => {
              let adw_db_relation = data.adw_db_relation;
              if (adw_db_relation && adw_db_relation.length) {
                this.line_items[index].ad_select = [];
                for (let i = 0; i < adw_db_relation.length; i++) {
                  this.line_items[index].ad_select.push({
                    id: adw_db_relation[i].id,
                    text: adw_db_relation[i].name,
                    value: adw_db_relation[i].id,
                  });
                }

                /*let _this = this;
                setTimeout(function() {
                  _this.calculate_level_one(index);
                }, 300);*/
              }
            })
            .catch((error) => {
              console.log({ error });
            })
            .finally(() => {
              // this.pageLoading = false;
            });
          this.$nextTick(() => {
            this.update_value(index);
          });
        }
      }
    },
    push_adtitle() {
      this.line_items.push({
        id: null,
        status: false,
        edit_supplier_cost: false,
        label: null,
        child_items: [],
        ad_select: [],
        show_label: false,
        child_sum: 0,
        optional_child_sum: 0,
        product: null,
        barcode: null,
        product_image:
          "https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/interior-design/files/image/png/b2GK1GLvOuwFlS8tRzKrdBqYd5tvknZ4eY2xLUow.png",
        product_type: "adtitle",
        product_id: 0,
        net_price: 0,
        package_id: null,
        new_item: true,
        description: null,
        quantity: 1,
        strike: 0,
        uom: null,
        rate: 0,
        total: 0,
        company_cost: 0,
        charges: 0,
        profit: 0,
        is_optional: 0,
      });
    },
    add_level_two_item(index, cindex) {
      if (
        this.lodash.isArray(this.line_items[index].child_items[cindex].items)
      ) {
        this.line_items[index].child_items[cindex].items.push({
          description: null,
          quantity: 1,
          uom: null,
          is_optional: 0,
          new_item: true,
          edit_supplier_cost: false,
          company_cost: null,
          strike: 0,
          rate: 0,
          total: 0,
        });
      }
    },
    marked_removed(val, index, cindex) {
      for (
        let i = 0;
        i < this.line_items[index].child_items[cindex].items.length;
        i++
      ) {
        if (val == 1) {
          this.line_items[index].child_items[cindex].items[i].strike = 1;
        } else {
          this.line_items[index].child_items[cindex].items[i].strike = 0;
        }
      }

      let _this = this;
      setTimeout(function () {
        _this.update_child_total(index, cindex);
      }, 300);
    },
    marked_optional(val, index, cindex) {
      for (
        let i = 0;
        i < this.line_items[index].child_items[cindex].items.length;
        i++
      ) {
        if (val == 1) {
          this.line_items[index].child_items[cindex].items[i].is_optional = 1;
        } else {
          this.line_items[index].child_items[cindex].items[i].is_optional = 0;
        }
      }

      let _this = this;
      setTimeout(function () {
        _this.update_child_total(index, cindex);
      }, 300);
    },
    update_optional_new_row(val, index, cindex, clindex) {
      if (val == 0) {
        if (
          clindex &&
          ObjectPath.has(
            this.line_items,
            `${index}.child_items.${cindex}.items.${clindex}.new_item`
          )
        ) {
          ObjectPath.set(
            this.line_items,
            `${index}.child_items.${cindex}.items.${clindex}.new_item`,
            true
          );
        } else if (
          ObjectPath.has(
            this.line_items,
            `${index}.child_items.${cindex}.new_item`
          )
        ) {
          ObjectPath.set(
            this.line_items,
            `${index}.child_items.${cindex}.new_item`,
            true
          );
          let childrens = ObjectPath.get(
            this.line_items,
            `${index}.child_items.${cindex}.items`,
            []
          );
          if (childrens && childrens.length) {
            for (let z = 0; z < childrens.length; z++) {
              ObjectPath.set(
                this.line_items,
                `${index}.child_items.${cindex}.items.${z}.new_item`,
                true
              );
            }
          }
        }
      }
    },
    update_child_total(index, cindex) {
      let { quantity, rate } = this.line_items[index].child_items[cindex];
      this.line_items[index].child_items[cindex].total =
        this.lodash.toNumber(quantity) * this.lodash.toNumber(rate);
      this.calculate_level_one(index);
      this.$nextTick(() => {
        this.update_value(index);
      });
    },
    update_child_level_one_total(index, cindex, clindex) {
      let { quantity, rate } =
        this.line_items[index].child_items[cindex].items[clindex];
      this.line_items[index].child_items[cindex].items[clindex].total =
        this.lodash.toNumber(quantity) * this.lodash.toNumber(rate);
      this.calculate_level_two(index, cindex);
      this.$nextTick(() => {
        this.update_value(index);
      });
    },
    calculate_level_one(index) {
      let level_one_active = this.lodash.filter(
        this.line_items[index].child_items,
        function (row) {
          return row.is_optional == 0 && row.strike == 0;
        }
      );

      let level_one_sum_child = this.lodash.map(level_one_active, "child_sum");

      let level_one_sum_total = this.lodash.map(level_one_active, "total");

      let level_one_optional = this.lodash.filter(
        this.line_items[index].child_items,
        function (row) {
          return row.is_optional == 1;
        }
      );

      let level_optional_sum_child = this.lodash.map(
        level_one_active,
        "optional_child_sum"
      );

      let level_optional_sum_total_child = this.lodash.map(
        level_one_optional,
        "child_sum"
      );

      let level_optional_sum_total = this.lodash.map(
        level_one_optional,
        "total"
      );

      this.line_items[index].child_sum = this.lodash.sum([
        ...level_one_sum_child,
        ...level_one_sum_total,
      ]);

      this.line_items[index].optional_child_sum = this.lodash.sum([
        ...level_optional_sum_child,
        ...level_optional_sum_total,
        ...level_optional_sum_total_child,
      ]);
    },
    calculate_level_two(index, cindex) {
      let level_two_active = this.lodash.filter(
        this.line_items[index].child_items[cindex].items,
        function (row) {
          return row.is_optional == 0 && row.strike == 0;
        }
      );

      let level_two_sum = this.lodash.map(level_two_active, "total");

      let level_two_optional = this.lodash.filter(
        this.line_items[index].child_items[cindex].items,
        function (row) {
          return row.is_optional == 1;
        }
      );

      let level_optional_sum = this.lodash.map(level_two_optional, "total");

      this.line_items[index].child_items[cindex].child_sum =
        this.lodash.sum(level_two_sum);

      this.line_items[index].child_items[cindex].optional_child_sum =
        this.lodash.sum(level_optional_sum);

      this.calculate_level_one(index);
    },
    add_child_item(index) {
      if (this.lodash.isArray(this.line_items[index].child_items)) {
        this.line_items[index].child_items.push({
          description: null,
          quantity: 1,
          uom: null,
          show_label: false,
          edit_supplier_cost: false,
          company_cost: null,
          label: null,
          is_optional: 0,
          strike: 0,
          optional_child_sum: 0,
          new_item: true,
          child_sum: 0,
          items: [],
          rate: 0,
          total: 0,
        });
      }
    },
    calc_child_supplier(index, cindex) {
      let { company_cost, quantity } =
        this.line_items[index].child_items[cindex];
      let output = company_cost * quantity;
      return this.formatMoney(output);
    },
    calc_child_one_supplier(index, cindex, clindex) {
      let { company_cost, quantity } =
        this.line_items[index].child_items[cindex].items[clindex];
      let output = company_cost * quantity;
      return this.formatMoney(output);
    },
    edit_supplier(index) {
      this.line_items[index].edit_supplier_cost = true;
      this.$nextTick(() => {
        this.$refs[`supplier-cost${index}`][0].$refs.input.focus();
      });
    },
    edit_child_one_supplier(index, cindex, clindex) {
      this.line_items[index].child_items[cindex].items[
        clindex
      ].edit_supplier_cost = true;
      this.$nextTick(() => {
        this.$refs[
          `clrow-supplier-price-${index}-${cindex}-${clindex}`
        ][0].$refs.input.focus();
      });
    },
    edit_child_supplier(index, cindex) {
      this.line_items[index].child_items[cindex].edit_supplier_cost = true;
      this.$nextTick(() => {
        this.$refs[
          `crow-supplier-price-${index}-${cindex}`
        ][0].$refs.input.focus();
      });
    },
    get_product_type(type) {
      if (type == "service") {
        return "Additional Work";
      } else {
        return type;
      }
    },
    delete_row_confirm() {
      this.delete_loading = true;
      this.$store
        .dispatch(DELETE, {
          url: `line-item/${this.delete_row.id}`,
        })
        .then(() => {
          this.delete_dialog = false;
          ObjectPath.del(this.line_items, this.delete_index);
          this.$nextTick(() => {
            this.emit_value();
            this.delete_row = {};
          });
        })
        .catch((error) => {
          console.log({ error });
        })
        .finally(() => {
          this.delete_loading = false;
        });
    },
    remove_child_level_one_row(row, clindex, cindex, index) {
      ObjectPath.del(this.line_items[index].child_items[cindex].items, clindex);
      this.calculate_level_two(index, cindex);
      this.$nextTick(() => {
        this.update_value(index);
      });
    },
    remove_child_row(row, cindex, index) {
      ObjectPath.del(this.line_items[index].child_items, cindex);
      this.calculate_level_one(index);
      this.$nextTick(() => {
        this.update_value(index);
      });
    },
    remove_row(row, index) {
      if (row.id) {
        this.delete_dialog = true;
        this.delete_row = row;
        this.delete_index = index;
      } else {
        ObjectPath.del(this.line_items, index);
        this.$nextTick(() => {
          this.update_value(index);
        });
      }
    },
    init_line_item(param) {
      const line_item = this.lodash.cloneDeep(param);
      if (line_item && line_item.length) {
        for (let i = 0; i < line_item.length; i++) {
          this.line_items.push(line_item[i]);
        }
      }
    },
    get_profit(index) {
      const line_item = this.lodash.cloneDeep(this.line_items[index]);
      const profit = line_item.rate - line_item.company_cost;
      return this.formatMoney(profit);
    },
    get_total_company_cost(index) {
      const line_item = this.lodash.cloneDeep(this.line_items[index]);
      const company_cost = line_item.company_cost * line_item.quantity;
      return this.formatMoney(company_cost);
    },
    get_total_charges(index) {
      const line_item = this.lodash.cloneDeep(this.line_items[index]);
      const charges = line_item.rate * line_item.quantity;
      return this.formatMoney(charges);
    },
    get_net_price(index) {
      const line_item = this.lodash.cloneDeep(this.line_items[index]);
      const charges = line_item.net_price * line_item.quantity;
      return this.formatMoney(charges);
    },
    get_total_profit(index) {
      const line_item = this.lodash.cloneDeep(this.line_items[index]);
      const profit =
        (line_item.rate - line_item.company_cost) * line_item.quantity;
      return this.formatMoney(profit);
    },
    update_value(index) {
      if (this.line_items[index]) {
        const { rate, quantity } = this.line_items[index];

        this.line_items[index].total = rate * quantity;

        this.$nextTick(() => {
          this.emit_value();
        });
      }
    },
    emit_value() {
      this.$emit("input", this.line_items);
      this.$emit("change", this.line_items);
    },
    get_line_items() {
      this.$store
        .dispatch(QUERY, {
          url: "line-item-services",
          data: {
            type: this.itemType,
            search: this.search,
            alphabet: this.alphabet,
            category: this.category,
          },
        })
        .then((response) => {
          this.line_items = ObjectPath.get(response, "data.line_items", []);
          this.categories = ObjectPath.get(response, "data.categories", []);
          this.emit_value();
        })
        .catch((error) => {
          this.logError(error);
          this.goBack();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.get_adtitle();
    this.get_additional_work_list();

    QuotationEventBus.$on("push-adtitle", () => {
      this.push_adtitle();
    });
  },
};
</script>
